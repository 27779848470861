@charset "utf-8";
@use "sass:color";
@import "settings";
//@import "assets/vendor/zurb/foundation/assets/foundation";
@import "assets/vendor/zurb/foundation/scss/foundation";
// @import "assets/components/motion-ui/motion-ui";
@import "flaticon";
@import "aktiv-base";
@import "slick";
@import "slick-lightbox";
// Or selectively include components
// Global styles //
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;
// Grids (choose one)
//@include foundation-xy-grid-classes;
// @include foundation-grid;
@include foundation-flex-grid;
// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;
// Containers
@include foundation-accordion;
@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;
// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;
// Helpers
//@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;
// Motion UI
//@include motion-ui-transitions;
//@include motion-ui-animations;
// FOR IE 10
@-ms-viewport {
  width: device-width;
}
// FONTS
//////////////////////////////////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,700;0,900;1,100;1,400;1,700;1,800&display=swap');
.poppins-bold-italic {
}
.logo-davis-adams {
  font-family: $body-font-family;
  font-style: italic;
}
.logo-attorneys {
  fill: #666;
  font-size: 108.2px;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  text-shadow: rgba($dark-gray, 1) 0 0 1px;
}
.logo-top {
  font-size: 219.66px;
  font-weight: bold;
  fill: #71b54e;
  letter-spacing: 0
}
.logo-adams {
  fill: #71b54e;
  font-weight: 600;
}
.logo-davis {
  fill: #027a0d
}
@include breakpoint(medium) {
  .logo-attorneys {
    font-weight: 300;
  }
}
// MIXINS
//////////////////////////////////////////////////////////////////////////
@function f-header-size($breakpoint, $size) {
  @return rem-calc(map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size") * 1px);
}
@mixin header-size($type) {
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  @include breakpoint(medium) {
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }
}
@mixin stak {
  // defaults;
  padding: 20px 0;
  p {
    font-size: 1em;
    &.lead {
      font-size: 100%;
      line-height: 1.6;
    }
  }
  @include breakpoint(medium) {
    // enhancements;
    padding: 41px 0;
  }
  @include breakpoint(large) {
    // enhancements;
    padding: 60px 0;
    p {
      font-size: 1em;
      &.lead {
        font-size: 125%;
        line-height: 1.65;
      }
    }
  }
}
@mixin hero-slide {
  // defaults;
  min-height: 40vh;
  height: auto !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  backface-visibility: hidden;
  align-items: center;
  display: flex;
  @include breakpoint(medium) {
    // enhancements;
    min-height: 55vh;
    height: auto;
  }
  @include breakpoint(large) {
    // enhancements;
    min-height: 60vh;
    height: auto;
  }
}
@mixin no-bullet {
  list-style-type: none;
  margin: 0;
}
@mixin stak-pic {
  @include stak;
  display: table;
  width: 100%;
  min-height: 300px;
  .row {
    display: table-row;
    width: 100%;
  }
  .columns {
    display: table-cell;
    float: none;
    vertical-align: middle;
    text-align: center;
  }
  .column:last-child:not(:first-child),
  .columns:last-child:not(:first-child) {
    float: none;
  }
  .vertical-center {
    vertical-align: middle;
  }
  h2 {
    //color: $white;
    line-height: initial;
    span {
      background-color: rgba($body-font-color, 0.8);
      padding: 0 0.25em;
      margin: 0;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
    strong {
      color: $black;
      display: inline-block;
    }
  }
  h3,
  li,
  p,
  strong {
    //color: $white;
  }
  @include breakpoint(large) {
    background-attachment: fixed;
  }
}
@mixin colored-stak {
  position: relative;
  &.photo-bgd-callout {
    background-blend-mode: overlay;
    &:after {
      content: "";
      position: absolute;
      mix-blend-mode: multiply;
      opacity: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      height: 100%;
      width: 100%;
    }
    .photo-bgd {
      &:after {
      }
    }
  }
  .content,
  .hero-caption {
    position: relative;
    z-index: 2;
  }
}
@mixin fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  left: 0;
  &.expanded:not(.top-bar) {
    height: auto;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
  }
  &.expanded:not(.top-bar) .title-area {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  &.expanded:not(.top-bar) .top-bar-section {
    margin-top: 5rem;
    z-index: 98;
  }
}
@mixin radius {
  border-radius: 4px;
}
.foo {
  @include grid-row;
  .bar {
    @include grid-column(12);
  }
}
@media screen and #{breakpoint(medium)} {
  .foo {
    @include grid-row;
    .bar {
      @include grid-column(4);
    }
  }
}
@mixin overlay {
  content: "";
  background: rgba($primary-color, .5);
  background: linear-gradient(135deg, rgba($primary-color, .75) 0%, rgba($dark-green, .25) 100%);
  position: absolute;
  mix-blend-mode: normal;
  opacity: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 0;
  height: 100%;
  width: 100%;
}
// DEFAULTS (* see _settings.scss for FOUNDATION OVERRIDES)
//////////////////////////////////////////////////////////////////////////
$logo-small: 160px;
$logo-medium: 200px;
$logo-large: 260px;
address {
  font-style: normal;
  margin-bottom: 2em;
  strong {
    color: $black;
  }
}
.columns {
  position: relative;
}
.fixed {
  position: fixed;
  width: 100%;
  z-index: 1;
}
$off-canvas-width: 60%;
.row.expanded {
  max-width: none;
  > .columns {
    padding: 0;
  }
}
:focus {
  outline: none;
}
a.has-tip {
  border-bottom: none;
  color: $primary-color;
  cursor: pointer;
  font-weight: normal;
}
.slick-arrow {
  z-index: 1;
}
.go-button {
  @include button;
  @include header-size(h5);
  margin-top: 1em;
  color: $white !important;
  overflow: hidden;
  width: 250px;
  cursor: pointer;
  white-space: nowrap;
  transition-timing-function: linear;
  transition: all 0.2s ease;
  margin: 0 auto 1em;
  position: relative;
  &:before {
    content: '';
    background: color.adjust($primary-color, $lightness: -10%) url("../images/site/arrowhead.png") center center no-repeat;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition-timing-function: linear;
    transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
    transition: all 0.2s ease-in-out 0s;
  }
  &:hover:before {
    transform: translateZ(0) scale(1, 1.1) translate(0%, 0);
  }
}
ul.link-list {
  list-style: none;
  margin: 0 auto;
  li:after {
    content: "\203A \20";
    padding-left: 0.25em;
  }
  li:hover:after {
    color: $orange;
  }
  a {
    display: inline-block;
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0.25em 0 0;
      background: transparent;
      transition: width 0.25s ease, background-color 0.25s ease;
    }
    &:hover:after {
      width: 100%;
      background: $orange;
    }
  }
}
ul.row {
  list-style: none;
  li {
    margin: 0.5em 0;
    line-height: 1.225em;
  }
}
.stak-pic {
  @include stak-pic;
}
.stak {
@include stak;
  //  background-color: $white;
  &.gradient {
    background: linear-gradient(180deg, rgba($light-gray, 1), rgba($light-gray, 0));
  }
  h2,
  h3 {
    font-weight: bold;
    &.light {
      font-weight: 300;
      line-height: 1.5em;
    }
    .section-header {
      position: relative;
      display: inline-block;
      margin: 0 auto 1em auto;
      &:after {
        content: "";
        // position: absolute;
        display: block;
        top: 100%;
        margin: .25em auto 0 auto;
        width: auto;
        height: 4px;
        background-color: $primary-color;
      }
    }
  }
  p {
    a:not(.button) {
      text-decoration: underline;
    }
  }
  li:not[role=presenation] {
  list-style-type: none;
  position: relative;
  line-height: 1.15em;
  padding-bottom: 1em;
  &:after {
    content: "\2022";
    position: absolute;
    top: 0;
    left: -2.5em;
    width: 2em;
    text-align: right;
    color: $dark-green;
  }
}
  .menu.vertical {
    li > a {
      font-weight: bold;
    }
    ul {
      margin-left: 2em;
      li > a {
        padding: 0.25rem .5rem;
        font-weight: normal;
      }
    }
  }
  .callout {
    border: none;
    box-shadow: 0px 8px 12px 0px rgba($gray, 0.15);
    border-radius: $global-radius;
    margin-bottom: 10%;
    figure {
      margin-bottom: 1em;
    }
    h3 {
      font-weight: 400;
      font-style: italic;
      color: $gray;
      font-size: f-header-size(small, h2);
    }
    p {
      color: $gray;
      font-size: .9em;
    }
  }
}
.green-stak,
.dark-green-stak {
  @include colored-stak;
  h1,
  h2,
  h3,
  h4 {
    .subheader {
      color: $white;
      display: block;
    }
    .section-header {
      &:after {
        background-color: $white;
      }
    }
  }
  *:not(.card-section) > {
    .button-group {
      .button.hollow {
        border-color: $white;
        color: $white;
        &:hover {
          background-color: $dark-green;
        }
      }
    }
    .button-group.hollow .button,
    .button.hollow {
      border-color: $white;
      color: $white;
      &:hover {
        background-color: $dark-green;
      }
    }
  }
}
.green-stak {
  //@include colored-stak;
  background-color: $primary-color;
  color: $white;
  h2,
  h3,
  blockquote {
    color: $white;
  }
  p {
    a {
      color: $dark-green;
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .callout {
    h2,
    h3,
    blockquote {
      color: $gray;
    }
  }
  &.photo-bgd-callout {
    &:after {
      background-color: $primary-color;
    }
  }
}
.green {
  color: $green;
}
.alt-stak {
  @include stak;
  background-color: $light-gray;
}
.stak.lead-stak {
  padding-bottom: 0;
  p {
    line-height: 1.6;
    @include breakpoint(large) {
      font-size: 125%;
      line-height: 1.65;
    }
  }
}
// prevent mobile no-scroll
.slick-slider {
  touch-action: auto;
  -ms-touch-action: auto;
}
.link-animated {
  font-style: italic;
  position: relative;
  display: inline-block;
  text-decoration: none !important;
  &:after {
    content: '';
    display: block;
    left: 0;
    height: 1px;
    width: 50%;
    background: $primary-color;
    transition: width 0.5s ease, background-color 0.25s ease;
  }
  &:hover:after {
    width: 100%;
    background: $primary-color;
  }
}
// GLOBALS (CUSTOM GLOBALS)
//////////////////////////////////////////////////////////////////////////
.skip-link.screen-reader-text {
  display: none;
}
.sticky-container {
  transition: all 0.25s;
  z-index: 999; // for dropdown pre-scroll
}
#stickyHeader {
  z-index: 100;
}
header.main-nav {
  background-color: rgba($white, 1);
  transition: all 0.25s;
  width: 100%;
  .logo {
    width: $logo-small;
    margin: 0.5em 0 0 0;
    transition: all 0.25s;
    a {
      padding: 0;
      margin: 0;
    }
  }
  @include breakpoint(medium) {
    position: fixed;
    .logo {
      width: $logo-medium;
    }
  }
  @include breakpoint(large) {
    // enhancements;
    .logo {
      width: $logo-large;
    }
  }
  @include breakpoint(xlarge) {
    // enhancements;
    // width: 100%;
    // position: fixed;
    // z-index: 2;
    // top: 0;
  }
  // GLOBAL-MENU ///////////////////////////////////////////////
  ul.global-menu {
    vertical-align: baseline;
    width: 100%;
    justify-content: flex-end;
    text-align: right;
    > li {
      position: relative;
      text-align: left;
    }
    > li > a,
    > li > button {
      font-size: 0.78em;
      padding: 0 1.25em;
      color: $dark-gray;
      line-height: 3em;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 0.5s;
      position: relative;
      text-align: left;
      &:last-child {
        padding-right: 0;
      }
      &:hover {
        color: $black;
        cursor: pointer;
      }
      &:after {
        content: '';
        display: block;
        left: 0;
        height: 3px;
        width: 0;
        background: $primary-color;
        transition: width 0.5s ease, background-color 0.25s ease;
      }
      &.hover:after,
      &:hover:after {
        width: 100%;
        background: $primary-color;
      }
      &.sel {
        color: $black;
        &:after {
          width: 100%;
          background: $primary-color;
        }
      }
    }
    @include breakpoint(large) {
      > li > a,
      > li > button {
        font-size: .9em;
        padding: 0 1.5em;
      }
    }
    @include breakpoint(xlarge) {
      // enhancements;
      > li > a,
      > li > button {
        font-size: 1em;
        padding: 0 2em;
      }
    }
  }
  .search-button {
    font-size: 1.5em;
    padding: 0 .5em 0 .5em;
    margin: 0 0 0 .25em;
    color: $dark-green;
    line-height: 2.5em;
    font-weight: 600;
    position: relative;
    text-align: left;
    cursor: pointer;
    display: inline-block;
    &:hover,
    &:focus {
      color: $primary-color;
    }
    &.hover {
      color: $white;
      background-color: $secondary-color;
      border-radius: 0;
    }
    .icon-search {
      padding: 0.25rem 0;
      &:before {
        font-size: 1em;
      }

    }
  }
  .search-pane {
    background-color: $secondary-color;
    border: none;
    transition: all 0.25s ease;
    input {
      background-color: $secondary-color;
      border: 1px solid $white;
      color: $white;
      box-shadow: none;
      &::placeholder {
        color: $white;
      }
    }
  }
  .dropdown-pane {
    font-size: 0.9em;
    text-align: left;
    box-shadow: 0px 8px 12px 0px rgba($gray, 0.15);
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    a {
      color: $dark-green;
      font-weight: bold;
      &:hover {
        color: $primary-color;
        text-decoration: none;
      }
    }
    &.mega-menu {
      font-size: 80%;
      font-size: 80%;
      width: 100% !important;
      background-color: transparent;
      padding: 0;
      box-shadow: none;
      &.is-open {
        left: 0 !important;
        display: block;
      }
      .litigation-list {
        background-color: $light-gray;
        padding: 1em 2em 2em 2em;
        border-bottom-left-radius: $global-radius;
        .menu {
          a {
            line-height: 1.15em;
            text-transform: uppercase;
            padding: 0.7rem 0 0 0;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
      .container {
        background-color: $white;
        box-shadow: 0px 8px 12px 0px rgba($gray, 0.15);
        border-bottom-right-radius: $global-radius;
        border-bottom-left-radius: $global-radius;
      }
      .content {
        padding: 1.25em;
        .description {
          border-top: 1px solid $light-gray;
          padding-top: 0.5em;
          color: $gray;
          p {
            line-height: 1.25em;
          }
        }
        .menu .button,
        .menu a {
          line-height: 1.15em;
          text-decoration: none;
          display: block;
          padding: 0 0 0.7rem 0;
          font-size: .9em;
        }
        .block {
          margin-bottom: 1em;
          a {
            font-weight: normal;
            &.nav-title {
              @include header-size(h6);
              font-weight: 700;
              letter-spacing: 0.05em;
              display: block;
            }
          }
          .menu-ico {
            max-width: 30px;
            display: inline;
            padding-right: 6px;
            line-height: 1em;
            margin: 0;
          }
        }
      }
      img {
        width: 100%;
        margin: 0.75em 0;
        display: block;
      }
      //@include breakpoint(xlarge) {
      //   font-size: 100%;
      //}
    }
    .vertical.menu {
      a {
        text-transform: uppercase;
        line-height: 1.15em;
      }
    }
  }
}
.page-template-default {
  header.main-nav {
    position: relative !important;
  }
}
.is-stuck {
  header.main-nav {
    background-color: rgba($white, 0.95);
    height: auto;
    @include breakpoint(xlarge) {
      // enhancements;
      .logo {
        width: $logo-medium;
      }
    }
  }
}
// ancillary
.ancillary {
  text-align: right;
  display: block;
  background-color: $light-gray;
  ul.ancillary-menu {
    margin: 0 !important;
    font-size: 0.8em;
    padding: 0.5em 0;
    list-style-type: none;
    text-transform: uppercase;
    justify-content: flex-end;
    li {
      list-style-type: none;
      padding: 0;
      @include breakpoint(medium) {
        padding: 0 0 0 10px;
      }
      a {
        color: $dark-gray;
        padding: 0.5rem;
        position: relative;
        display: inline-block;
        line-height: 1em;
        font-weight: bold;
        &:hover {
          color: $black;
        }
      }
      form {
        display: inline;
      }
    }
  }
  .ico-da-tv {
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -17px;
      top: 6px;
      height: 13px;
      width: 24px;
      line-height: 1em;
      background-image: url("../images/ico-da.png");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
  .phone {
    font-weight: bold;
    @include breakpoint(medium) {
      margin-right: 1.5em;
    }
    &:before {
      font-family: "Flaticon";
      content: "\e138";
      position: absolute;
      left: -8px;
      top: .6em;
      height: 1em;
      width: 1em;
    }
  }
  .contact {
    @include button;
    @include button-hollow;
    font-size: 1em;
    border: color.adjust($secondary-color, $lightness: 20%) 1px solid;
    color: $secondary-color;
    margin-bottom: 0;
    transition: all 0.25s;
    margin: 0;
    a {
      color: $secondary-color;
    }
    &:hover {
      border: $secondary-color 1px solid;
    }
  }
  // .x-collapse {
  //    .columns,
  //    .postfix,
  //    a,
  //    button,
  //    input {
  //       margin: 0 !important;
  //    }
  // }
  // input:focus {
  //    outline: none;
  // }
  // .search {
  //    background-color: transparent;
  //    border-color: transparent;
  //    border: none;
  //    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0);
  //    width: 30px;
  //    height:30px;
  //    background: url("../images/search.png") no-repeat scroll 4px center transparent;
  //    float: right;
  //    color: $gray;
  //    padding: 4px;
  //    margin-right: 10px;
  //    text-indent: 30px;
  //    font-size: 1em;
  //    border-radius: 4px;
  //    cursor: pointer;
  //    &::-webkit-input-placeholder {
  //       color: lighten($gray, 20%);
  //    }
  // }
  // .open {
  //    background-color: $white;
  // }
}
// MOBILE NAVIGATION
/////////////////////////////////////
/// 
//.title-bar {
//  text-align: center;
//  padding: 0.5em 0 0.25em;
//  line-height: 1em;
//  .logo {
//    margin: 0 auto;
//    padding: 0;
//    @include breakpoint(small down) {
//      margin-left: 15px;
//    }
//    .fill-black {
//      fill: $graphite;
//    }
//  }
//}
//.mobile-title-bar {
//  padding: 0;
//  min-height: 72px;
//  .logo {
//    a {
//      display: inline-block;
//      width: 165px;
//    }
//  }
//}
.menu-ico {
  //margin: -2px 0.3em;
  border-radius: 0;
  transition: 0.3s;
  cursor: pointer;
  user-select: none;
  // position: absolute;
  //background-color: $dark-green;
  display: inline-block;
  span {
    display: inline-block;
    width: 2.5rem;
    height: 0.26rem;
    background: $dark-green;
    border-radius: 0.5em;
    transition: 0.3s;
    position: relative;
  }
  span:before {
    display: inline-block;
    width: 2.5rem;
    height: 0.26rem;
    background: $dark-green;
    border-radius: 0.5em;
    transition: all 0.3s;
    position: absolute;
    top: 0.75em;
    left: 0;
    content: '';
    transform-origin: 0.13rem center;
  }
  span:after {
    display: inline-block;
    width: 2.5rem;
    height: 0.26rem;
    background: $dark-green;
    border-radius: 0.5em;
    transition: all 0.3s;
    position: absolute;
    top: -.75em;
    left: 0;
    content: '';
    transform-origin: 0.13rem center;
    //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
  }
  &:hover {
    span {
      background: $primary-color;
    }
    span:before {
      background:  $primary-color;
    }
    span:after {
      background:  $primary-color;
      //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    }
  }
  &[aria-expanded="true"] {
    //background-color: $primary-color;
    span {
      background: transparent !important;
    }
    span:before {
      //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
      transform: rotate3d(0, 0, 1, 45deg);
      transform-origin: 50% 50%;
      top: 0;
      background-color: $green;
    }
    span:after {
      //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
      transform: rotate3d(0, 0, 1, -45deg);
      transform-origin: 50% 50%;
      top: 0;
      background-color: $green;
    }
  }
}

.right-off-canvas-menu {
  padding-top: 3.75rem;
  .menu li a {
    display: block;
    border-bottom: 1px solid color.adjust($light-gray, $lightness: -10%);
    border-top: 1px solid $white;
    color: $dark-gray;
    &:hover {
      color: $primary-color;
    }
    &.sel {
      color: $black;
    }
  }
  .menu li:first-of-type {
    a {
      border-top: none;
    }
  }
  .menu li:last-of-type {
    a {
      border-bottom: none;
    }
  }
  .is-submenu-item {
    font-size: .9em;
    a{
      padding: 0.5rem 1rem 0 1rem;
      line-height: 1.25em;
    }
  }
  .searchform {
    input {
      padding: 1em;
    }
  }
}
// GLOBAL FOOTER
/////////////////////////////////////
footer.global-footer {
  background-color: color.adjust($light-gray, $lightness: -2%);
  padding: 2em 0 0;
  font-size: 0.9em;
  //*:not(.button){color: $gray;}
  text-align: center;
  h5 {
    font-weight: bold;
    margin-top: 1em;
    &.follow-us {
      display: inline;
      margin-right: 1em;
    }
  }
  .logo {
    vertical-align: middle;
    margin: 0 auto 1em auto;
    max-width: $logo-medium;
  }
  .social-icons {
    font-size: 1.5em;
    margin-bottom: .5em;
    display: inline;
    a {
      margin: 0 .25em;
      display: inline-block;
      min-width: 40px;
      min-height: 40px;
      &:hover {
        text-decoration: none;
      }
    }
    @include breakpoint(medium) {
      a {
        margin-left: -.5em;
        margin-right: 0.5em;
      }
    }
    [class^="flaticon-"]:hover:after,
    [class*="flaticon-"]:hover:before {
      color: color.adjust($primary-color, $lightness: 10%);
    }
  }
  ul.display-posts-listing {
    @include no-bullet;
    li {
      padding: 0.2em 0;
      &:first-of-type {
        padding: 0 0 0.5em;
      }
    }
    a {
      text-decoration: underline;
    }
    // a:hover {
    //    color: lighten($primary-color, 50%);
    // }
  }
  .copyright {
    @include grid-column(12);
    margin: 1em auto;
    text-align: center;
    color: $gray;
    p {
      font-size: 0.9em;
      //color: $white;
      vertical-align: middle;
    }
  }
  a:not(.button):hover {
    text-decoration: underline;
  }
  .telephone {
    display: block;
    margin: 0.5em 0;
    span {
      display: inline-block;
      width: 1em;
    }
  }
  //.button {
  //  @include button($expand: true, $background: $white, $style: hollow);
  //  transition: all, 0.25s;
  //  border-width: 2px;
  //  &:hover {
  //    color: lighten($primary-color, 50%);
  //    border-color: lighten($primary-color, 50%);
  //  }
  //}
  @include breakpoint(medium) {
    text-align: left;
    h5 {
      margin-top: 0;
    }
    .logo {
      margin: 0 0 1em 0;
    }
    ul.display-posts-listing {
      @include no-bullet;
      li {
        border-bottom: 1px solid $white;
        padding: 0.5em 0;
        &:first-of-type {
          padding: 0 0 0.5em;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
}
.icon-callouts {
  img {
    padding-right: 0.51em;
  }
  a:hover {
    h4 {
      color: $primary-color;
    }
  }
}
// HOMEPAGE
//////////////////////////////////////////////////////////////////////////
#typed-subtitles {
  display: none;
}
#home-top-bar {
  width: 100%;
}
.off-canvas-wrapper {
  .home-hero {
    margin-top: 0;
    background-image: linear-gradient(160deg, #002712, #00a350);
  }
}
@media only screen and (max-width: 600px) {
  .off-canvas-wrapper .hero-image {
    height: 34vh;
  }
}
@media screen and (max-width: 39.9375em) {
  .off-canvas-wrapper {
    .hero-image {
      height: 34vh;
    }
  }
}
.click-to-call {
  display: inline-block;
  text-align: center;
  background-color: $primary-color;
  width: 100%;
  border-radius: 0;
  box-shadow: inset 0px 0px 0 2px $white;
  border: 3px solid $primary-color;
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-size: 36px;
  font-weight: 900;
  line-height: 43.57px;
  text-align: left;
  color: $white;
  gap: 0;
  padding: 10px;
  &::before {
    font-family: "Flaticon";
    content: "";
  }
}
.home-hero {
  .home-hero-load-img {
    height: 1px;
    width: 1px;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
  }
  margin: 0;
  backface-visibility: hidden;
  background-image: linear-gradient(160deg, #002712, #00a350);
  @include hero-slide;
  text-align: center;
  .hero-caption {
    h1 {
      font-weight: 900;
    }
    h2 {
      font-weight: 300;
      margin: 0;
    }
    h1,
    h3 {
      color: $white;
      line-height: 1em;
    }
    h2 {
      color: $white;
      line-height: normal;
    }
    p {
      color: $white;
      font-size: $paragraph-font-size;
      margin-top: 1em;
      a {
        color: $primary-color;
      }
    }
    .button {
      @include button;
      margin-top: 2em;
    }
  }
  @include breakpoint(medium) {
    .hero-caption {
      h1 {
        font-size: f-header-size(small, h1);
      }
      h2 {
        font-size: f-header-size(small, h2);
      }
      .button {
        @include button;
        margin-top: 2em;
      }
    }
  }
  @include breakpoint(large) {
    .hero-caption {
      padding: 100px 0 0;
      h1 {
        font-size: f-header-size(medium, h1);
        line-height: 1.15em;
      }
      h2 {
        font-size: f-header-size(medium, h2);
      }
      p {
        font-size: $paragraph-font-size + 0.25;
      }
    }
  }
  @include breakpoint(xlarge) {
    .hero-caption {
      h1 {
        font-size: f-header-size(medium, h1) + .5;
      }
    }
  }
}
.hover-callouts {
  margin: 0 auto;
  > div {
    vertical-align: bottom;
    transition: all 0.5s;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    overflow: hidden;
    background-clip: content-box !important;
    min-height: 300px;
    display: table;
    border: 4px solid $white;
    &:first-of-type {
      border-bottom: 4px solid $white;
    }
    > div {
      border-collapse: collapse;
      display: table-row;
      background-color: rgba($dark-gray, .9);
    }
    .meta {
      height: 96%;
      text-align: center;
      border: 1px solid $transparent;
      padding: 0.5em;
      margin: 0.5em 0 0;
      display: table-cell;
      vertical-align: middle;
      h3,
      h4 {
        font-weight: 400;
        color: $white;
        margin-bottom: 0;
        line-height: 1.25em;
      }
      hr {
        margin: 0.5em 0;
        border-color: teal;
      }
      p {
        color: $white;
        margin: 1em 0;
      }
    }
    @include breakpoint(medium) {
      overflow: hidden;
      background-size: cover !important;
      transition: all 0.25s;
      min-height: 400px;
      display: block;
      > div {
        background-color: rgba($dark-gray, 0.65);
        transition: all 0.5s;
        height: 100%;
        padding: 0.5em;
        display: block;
      }
      .meta {
        transform: translate(0px, 40%);
        transition: all 0.5s;
        display: block;
        h3 {
          color: $white;
        }
        p {
          color: $white;
          opacity: 0;
          transition-delay: 0;
        }
        hr {
          opacity: 0;
          transition-delay: 0;
          width: 80%;
          margin-right: auto;
          margin-left: auto;
        }
        a {
          opacity: 0;
          transition-delay: 0;
        }
      }
      &:hover {
        > div {
          background-color: rgba($dark-gray, .9);
        }
        .meta {
          transform: translate(0px, 30%);
          transition-delay: 0.25s;
          p {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.25s;
          }
          hr {
            opacity: 1;
            transition-delay: 0.25s;
          }
          a {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.5s;
          }
        }
      }
    }
    &:hover {
      background-size: 120%;
    }
  }
}
.stats {
  margin-top: 2em;
  font-family: $header-font-family;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  .column {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  strong {
    font-weight: 600;
    display: block;
    font-size: 1.5em;
    line-height: 1.5em;
    color: $primary-color;
  }
  @include breakpoint(large) {
    font-size: 1em;
  }
}
.testimonials {
  text-align: center;
  .row {
    align-items: center;
    justify-content: center;
  }
}
.stak.testimonials {
  .slick-next {
    right: -20px;
  }
  .slick-prev {
    left: -20px;
  }
}
.quote-slider,
.quote-slider-home,
.client-reviews {
  blockquote {
    margin: 30px 0 0;
    border-left: none;
    p {
      margin: 0;
      letter-spacing: 0.1em;
      color: unset;
      &:before {
        content: '“';
      }
      &:after {
        content: '”';
      }
    }
    footer {
      margin: 1em 0;
      font-weight: bold;
      border-top: 1px rgba($dark-green, .5) solid;
      padding-top: 1em;
    }
    @include breakpoint(medium) {
      p {
        font-size: 110%;
      }
    }
    .category-link {
      font-size: .9em;
      font-weight: normal;
      display: block;
    }
  }
  .star-rating {
    display: block;
    height: 1.5em;
    line-height: 1.5em;
    &:before {
      font-family: Flaticon, serif;
      content: "\e129 \e129 \e129 \e129 \e129";
      //top: 0;
      //right: -2em;
      //transition: all .25s;
      //font-weight: bold;
      color: $orange;
      font-size: 1em;
    }
  }
}
.client-reviews {
  .card {
    box-shadow: 0px 4px 8px 0px rgb(102 102 102 / 15%);
    border-radius: 4px;
  }
}
.quote-slider-home {
  .slick-slide {
    text-align: left;
    padding: 0 2em;
    @include breakpoint(large) {
      padding: 0 2em 0 4em;
    }
  }
  .slick-next,
  .slick-prev {
    &:before {
      color: $dark-green;
    }
  }
}
.malpractice-slider,
.content-slider-bio {
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
    margin-right: 1em;
    padding: 1em 0 2.5em 1em;
    background-color: $white;
  }
  .slick-next,
  .slick-prev {
    bottom: 0;
    background-color: $dark-green;
    z-index: 1;
    height: 40px;
    width: 40px;
    border-radius: 0;
    &:before {
      color: $white;
      font-size: 28px;
      height: 40px;
      width: 40px;
      text-align: center;
      justify-content: center;
      margin-left: 0;
      margin-left: -6px;
      margin-top: 6px;
    }
  }
  .description {
    font-size: .9em;
  }
  .slick-next {
    right: unset;
    left: 40px;
    background-color: $primary-color;
    border-top-right-radius: $global-radius;
  }
  .link-animated {
    margin-top: .5em;
    text-decoration: none;
    span {
      display: none;
    }
  }
  .title a {
    position: relative;
    margin-bottom: 1em;
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      bottom: -4px;
      left: 0;
      transition: all ease-in-out .5s;
    }
    &:hover {
      &:after {
        width: 100%;
        background-color: $primary-color;
      }
    }
  }
  @include breakpoint(large) {
    .title a {
      margin-bottom: 2em;
    }
    .slick-slide {
      padding: 2em;
    }
  }
}
.showroom-callout {
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($white, .5);
    top: 0;
  }
}
.client-logos {
  margin: 20px 0 0;
  .ri-grid {
    margin: 0 auto;
    position: relative;
    height: auto;
  }
  .ri-grid ul {
    list-style: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  /* Clear floats by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
  .ri-grid ul:after,
  .ri-grid ul:before {
    content: '';
    display: table;
  }
  .ri-grid ul:after {
    clear: both;
  }
  .ri-grid ul {
    zoom: 1;
    /* For IE 6/7 (trigger hasLayout) */
  }
  .ri-grid ul li {
    perspective: 250px;
    //height: 200px!important;
    margin: 0;
    padding: 0;
    float: left;
    position: relative;
    display: block;
    overflow: hidden;
    //border: 1em solid $white;
    @include breakpoint(medium) {
      //border: 1em solid transparent;
    }
  }
  .ri-grid ul li a,
  .ri-grid ul li div {
    display: block;
    outline: none;
    position: absolute;
    z-index: 1002;
    margin: 0 auto;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    background-size: 90%;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: content-box;
    filter: grayscale(100%) contrast(110%);
    transition: all 0.25s ease-in-out;
    &:hover {
      filter: grayscale(0%) contrast(100%);
    }
  }
  /* Grid wrapper sizes */
  .ri-grid-size-1 {
    width: 55%;
  }
  .ri-grid-size-2 {
    width: 100%;
  }
  .ri-grid-size-3 {
    width: 100%;
    //margin-top: 0;
  }
  .ri-grid-loading:after,
  .ri-grid-loading:before {
    display: none;
  }
  .ri-loading-image {
    display: none;
  }
  .ri-grid-loading .ri-loading-image {
    position: relative;
    width: 30px;
    height: 30px;
    left: 50%;
    margin: 100px 0 0 -15px;
    display: block;
  }
}
// ABOUT
//////////////////////////////////////////////////////////////////////////
.landing {
  h2 {
    display: initial;
  }
  .hero {
    background-size: 100%;
    position: relative;
    min-height: 100%;
    background-position: right center;
    background-size: cover !important;
    background-repeat: no-repeat;
    .caption-container {
      align-self: flex-end;
      @include breakpoint(large) {
        align-self: center;
      }
    }
    .hero-banner {
      display: flex;
    }
    .hero-image {
      text-align: right;
      background-position: right center;
      background-size: cover !important;
      min-height: 280px;
    }
    .hero-video {
      padding: 1em 0 0 0;
    }
    .hero-caption {
      padding: 2em;
      position: relative;
      h1,
      h2 {
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        font-weight: 700;
      }
      p {
        font-size: $paragraph-font-size - .25;
        a {
          font-weight: 700;
          display: inline-block;
          &:after {
            content: '';
            display: block;
            left: 0;
            height: 3px;
            width: 50%;
            background: $primary-color;
            transition: width 0.5s ease, background-color 0.25s ease;
          }
          &:hover:after {
            width: 100%;
            background: $primary-color;
          }
        }
      }
    }
    @include breakpoint(medium) {
      .hero-image {
        min-height: 500px;
        z-index: -1em;
        position: relative;
        //   background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 10%);
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 20%);
        }
      }
      .hero-video {
        padding: 2em 2em 2em 0;
      }
    }
    @include breakpoint(large) {
      .hero-image {
        min-height: 500px;
      }
      .hero-video {
        padding: 6em 2em 2em 0;
      }
      .hero-caption {
        margin-right: -6em;
        padding: 6em 4em 0 4em;
        z-index: 2;
        h1 {
          @include header-size(h1);
        }
        h2 {
          @include header-size(h2);
        }
        p {
          font-size: $paragraph-font-size;
        }
      }
    }
    @include breakpoint(xxlarge) {
      .hero-caption {
        margin-left: 10vw;
      }
    }
  }
}
@media screen and (max-width: 39.9375em) {
  .hero-caption {
    margin-top: 20px;
  }
}
// FLEXIBLE CONTENT BLOCKS
.photo-bgd-callout {
  background-position: center center !important;
  background-size: cover;
  .content {
    margin: 2em auto;
    padding: 1em;
    background-color: rgba($green, .9);
    text-align: center;
  }
  @include breakpoint(medium) {
    background-attachment: fixed !important;
    .content {
      margin: 4em auto;
      padding: 1em 2em;
      background-color: transparent;
    }
  }
}
.cards-stak {
  .card-stak-heading {
    padding-bottom: 1em;
  }
  .card-description {
    padding-bottom: .5em;
  }
  .card {
    padding: 1em;
    transition: .25s all ease-in-out;
    h5 {
      font-weight: bold;
    }
    figure {
      position: relative;
      // background-color: $primary-color;
      height: 8em;
      overflow: hidden;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all ease-in-out .5s;
      }
    }
    img {
      // mix-blend-mode: multiply;
      transition: all ease-in-out .5s;
      height: auto;
      width: 100%;
      box-sizing: unset;
    }
    &:hover {
      box-shadow: 0px 8px 12px 0px rgba($gray, 0.15);
      img {
        transform: scale(1.1);
      }
      figure {
        position: relative;
        // background-color: $primary-color;
        &:after {
          opacity: 0;
        }
      }
      .link-animated {
        &:after {
          width: 100%;
          background: $primary-color;
        }
      }
    }
    a {
      p {
        color: $gray;
      }
    }
  }
  &.green-stak,
  .dark-green-stak {
  }
}
.jess-davis {
  .cards-stak {
    .card {
      padding: 0;
    }
  }
}
.cards-stak.bio-cards {
  text-align: center;
  .row.align-top {
    justify-content: center;
  }
  .card {
    figure {
      position: relative;
      height: unset;
    }
    img {
      // mix-blend-mode: multiply;
      transition: all ease-in-out .5s;
    }
    &:hover {
      figure {
        height: unset;
      }
      img {
        transform: scale(1.1);
      }
    }
  }
}
// VIDEO
.flex-video {
  iframe {
    border: 0;
  }
}
.video-backbground {
  background-color: $light-gray;
  padding: 15px;
  margin: 0 15px !important;
}
.video-player {
  // background-color: #fff;
  padding-bottom: 5px;
  .poster {
    position: relative;
    img {
      width: 100%;
    }
  }
  .play-button {
    position: absolute;
    width: 80px;
    height: 80px;
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.5s ease;
    &:hover {
      opacity: 0.5;
    }
  }
}
.split-image {
  img {
    margin-bottom: 2em;
    box-shadow: 0px 8px 12px 0px rgba($gray, 0.15);
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}
h3,
h4 {
  &.underline {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background-color: $gray;
    }
  }
  line-height: 1.15em;
}
.align-middle,
.collapse,
.fill-image {
  .content {
    padding: 2em;
  }
  @include breakpoint(xlarge) {
    .content {
      padding: 4em;
    }
  }
}
.fill-image {
  .columns {
    min-height: 200px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    @include breakpoint(medium) {
      background-size: auto 100%;
    }
  }
}
.alternating-staks {
  .stak {
    &:nth-child(odd) {
      background-color: $light-gray;
    }
  }
  img.thumbnail {
    margin: 0.5em auto;
    width: 90%;
  }
}
.gallery-photo {
  &.slick-slider {
    margin-bottom: 4px !important;
  }
  > div {
    // max-width: 320px;
    overflow: hidden;
    vertical-align: middle;
    margin-bottom: 0;
    img {
      //max-width: 640px;
      width: 100%;
      vertical-align: middle;
      margin: 0 auto;
      transform: scale(1);
      transition: transform 0.25s;
    }
  }
}
.gallery-thumb {
  //max-width: 640px;
  max-height: 98px;
  background-color: $white;
  div.slick-slide {
    //min-width: 120px;
    max-height: 90px !important;
    overflow: hidden;
    vertical-align: middle;
    margin: 0;
    text-align: center;
    margin: 0 2px;
    .slick-list {
      max-height: 90px;
    }
    img {
      max-width: 120px;
      min-height: 90px;
      vertical-align: middle;
      padding: 0 4px 0 0;
      margin: 0 auto;
    }
    @include breakpoint(large) {
      img {
        max-width: 150px;
        min-height: 120px;
      }
    }
  }
  .slick-next,
  .slick-prev {
    top: 0;
    background-color: rgba($white, .9);
    z-index: 1;
    height: 100%;
    &:before {
      color: $black;
    }
  }
}
.photo-gallery {
  .row {
    justify-content: center;
    text-align: center;
  }
  &.badges {
    padding-top: 0;
    img {
      box-sizing: content-box;
      filter: grayscale(100%) contrast(110%);
      transition: all 0.25s ease-in-out;
      &:hover {
        filter: grayscale(0%) contrast(100%);
      }
    }
  }
}
.content-slider {
  margin-bottom: 4em;
  .slick-slide {
    padding: 1em;
  }
  @include breakpoint(medium) {
    margin-bottom: 2em;
  }
}
.accordion {
  .accordion-item {
    .accordion-title {
      border-bottom: 1px solid rgba($dark-green, .25);
      font-weight: bold;
      &:hover,
      &:focus {
        color: $primary-color;
        &::before {
          color: color.adjust($orange, $lightness: 10%);
        }
      }
      &::before {
        color: $orange;
      }
    }
    &.is-active {
      .accordion-title {
        background-color: $primary-color;
        color: $white;
        &::before {
          color: color.adjust($primary-color, $lightness: 10%);
        }
      }
    }
    @include breakpoint(medium) {
      .accordion-title {
        padding: 2em 1em;
      }
    }
  }
}
.green-stak {
  .accordion {
    //background-color: $white;
    .accordion-item {
      .accordion-title {
        border-bottom: 1px solid rgba($dark-green, .25);
        color: $white;
        &:hover,
        &:focus {
          color: $dark-green;
          background-color: color.adjust($primary-color, $lightness: 2%);
        }
      }
      &.is-active {
        .accordion-title {
          background-color: $dark-green;
          color: $primary-color;
        }
      }
    }
  }
}
// BLOG
/////////////////////////////////
// Article
.more-articles {
  padding-top: 2em;
  .button {
    border-radius: $button-radius;
  }
}
.article-cards,
.tease-post,
.tease-page {
  .card {
    transition: all 0.5s;
    display: block;
    background-color: $light-gray;
    overflow: hidden;
    //margin: .9em;
    p {
      font-size: .8em;
      margin: 1.5em auto 1.75em;
    }
    .date {
      color: $gray;
      font-size: .8em;
    }
    .article-thumb {
      width: 100%;
      display: block;
      position: relative;
    }
    .article-categories {
      margin-bottom: 1em;
      a {
        text-transform: uppercase;
        font-size: .8em;
        color: $secondary-color;
        font-weight: bold;
        &:hover {
          color: color.adjust($primary-color, $lightness: 8%);
        }
        &:before {
          content: ", ";
        }
      }
      a:first-of-type {
        &:before {
          content: "";
        }
      }
    }
    &:hover {
      img {
        width: 110%;
      }
    }
  }
  @include breakpoint(medium) {
    .card {
      transition: all 0.25s;
      display: block;
      margin: .9em;
      .card-section {
        transition: all 0.5s;
        padding: 1.5em 1em;
      }
    }
    padding-bottom: 2em;
  }
}
.breadcrumbs {
  margin-top: 2em;
  vertical-align: middle;
  font-size: 0.7em;
  color: $medium-gray;;
  a {
    color: $secondary-color;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    //font-size: 1em;
  }
  .breadcrumb_last {
    color: $medium-gray;
    cursor: not-allowed;
  }
}
.pagination-block {
  text-align: center;
  border-top: 2px solid $light-gray;
  padding-top: 2em;
  .disabled {
    color: color.adjust($gray, $lightness: 20%);
  }
  .prev {
    float: left;
  }
  .next {
    float: right;
  }
  .current {
    font-weight: bold;
  }
}
.page-template-template-landing .main-section.landing {
  margin-top: 95px;
  @include breakpoint(large down) {
    margin-top: 76px;
  }
  @include breakpoint(small down) {
    margin-top: 0;
  }
}
.single,
.category,
.archive,
.search {
  .main-section {
    margin-top: 95px;
    @include breakpoint(large down) {
      margin-top: 76px;
    }
    @include breakpoint(small down) {
      margin-top: 0;
    }
    h1 {
      font-weight: bold;
    }
  }
}
.article {
  h2,
  h3,
  h4 {
    font-weight: normal;
    b {
      font-weight: normal;
    }
  }
  p {
    a {
      color: color.adjust($primary-color, $lightness: 20%);
      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
  .cat-title-link {
    a {
      padding: 0 .25em;
      &:after {
        content: ","
      }
      &:last-of-type {
        &:after {
          content: "";
        }
      }
    }
  }
  .featured-image {
    margin-bottom: 2em;
  }
  .article-pagenation {
    margin: 2em auto;
    padding: 2em;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    a {
      h4 {
        color: $dark-gray;
        //text-decoration: underline;
        font-size: .9em;
        margin-bottom: 0;
      }
      span {
        font-size: .8em;
        line-height: 1em;
        display: block;
        color: color.adjust($primary-color, $lightness: 20%);
      }
      &:hover {
        h4,
        span {
          color: $secondary-color !important;
        }
      }
    }
    @include breakpoint(medium) {
      justify-content: space-between;
      .next-post {
        text-align: right;
      }
    }
  }
}
.recent-articles-list {
  margin-bottom: 1em;
  img {
    margin-right: 1em;
  }
  .article-date {
    font-size: .8em;
    margin-bottom: 0;
  }
  .article-title {
    h6 {
      margin-top: 0;
    }
    a:hover h6 {
      //   color.adjust($primary-color, $lightness: 8%);
    }
  }
}
.category-list {
  a {
    padding: 0.7rem 1rem .7rem 0;
    display: inline;
    font-size: .8em;
  }
  .article-count {
    display: inline-block;
    width: 1.5em;
    font-size: .8em;
    text-align: center;
    color: $gray;
    font-style: italic;
    //margin-right:  1rem;
  }
}
// .comment-box {
//    margin-top: 2em;
//    padding-top: 2em;
//    border-top: 4px solid $light-gray;
// }
// .comment-form {
//    input, textarea {
//       border: none;
//       background-color: $light-gray;
//       box-shadow: none;
//       color: $gray;
//       &::placeholder {
//          color: color.adjust($gray, $lightness: 20%);
//       }
//       &:focus::placeholder{
//          color:transparent;
//       }
//    }
//    input {
//       margin-bottom: 1em;
//    }
//    label {
//       display: none;
//    }
//    textarea {
//       min-height: 148px;
//    }
//    sup {
//       display: block;
//       font-family: $font-family-serif;
//       margin: 1em auto;
//       font-style: italic;
//       color: $gray;
//    }
//    h4 {
//       display: block;
//       font-style: normal;
//       text-transform: uppercase;
//       font-size: f-header-size(small, h6)*.8;
//       color: $primary-color;
//       font-weight: bold;
//       margin-bottom: 1em;
//    }
// }
// MALPRACTICE
.malpractice-header {
  @include breakpoint(large) {
    .content {
    }
  }
  #ninja_forms_required_items,
  .nf-field-container {
    margin-bottom: .5em;
  }
  .nf-form-content .list-select-wrap .nf-field-element > div,
  .nf-form-content input:not([type=submit]),
  .nf-form-content textarea {
    background: $light-gray !important;
    border: none;
    border-radius: .25em !important;
    box-shadow: none;
    color: #787878;
    transition: all .5s;
    height: auto;
  }
  .nf-form-content .listselect-wrap .nf-field-element div,
  .nf-form-content .ninja-forms-field {
    font-size: 16px;
    margin: 0;
    padding: .5em !important;
  }
  .nf-form-content {
    .nf-field-element {
      input.button.ninja-forms-field {
        padding: 1em !important;
        border-radius: .25em !important;
      }
    }
  }
}
.aside-sticky {
  z-index: 0;
}
.section-nav {
  margin: 0 auto 2em auto;
  display: inline-block;
  width: auto;
  ul > li {
    border-bottom: 2px solid $secondary-color;
    margin: 0;
  }
  li {
    a {
      display: block;
      color: $body-font-color;
      text-decoration: none;
      font-size: 1em;
      &:hover {
        color: $primary-color;
      }
      &.active {
        color: $primary-color;
      }
    }
    &.is-dropdown-submenu-parent > a {
      padding: 0 !important;
      &::after {
        display: none !important;
        //width: 0;
        //height: 0;
        //border-style: solid;
        //border-width: 6px;
        //content: "";
        //border-bottom-width: 0;
        //border-color: #0a0a0a transparent transparent;
        //right: 5px;
        //left: auto;
        //margin-top: -3px;
      }
    }
    &.section-nav-title {
      // text-transform: uppercase;
      width: 100%;
      position: relative;
      padding: .5em 0;
      font-size: .8em;
      font-weight: bold;
      a {
        color: $gray;
      }
      &:after {
        font-family: Flaticon, serif;
        content: "\e007";
        position: absolute;
        top: 0;
        right: -2em;
        transition: all .25s;
        font-weight: bold;
        color: $primary-color;
        font-size: 1.25em;
      }
      &.is-active {
        &:after {
          //content: "\e009";
          transform: rotate(90deg);
        }
      }
    }
    .js-dropdown-active {
      //border-top-color: $white;
      border: none;
      filter: drop-shadow(0 5px 5px #d9dae4);
      overflow: hidden;
      height: 400px;
      overflow-y: scroll;
      z-index: 9999999;
    }
    .is-dropdown-submenu a {
      padding: 0.5rem 1rem;
      width: 280px;
    }
    ul {
      list-style-type: none;
      padding-bottom: 1em;
      padding-inline-start: 0px;
      margin: 0;
      font-weight: normal;
      li {
        margin: 0;
        padding: 0;
        border: none;
        a {
          margin: 0;
          font-size: 1em;
          color: $secondary-color;
        }
      }
    }
  }
}
.sticky-menu {
  font-size: .9em;
  @include breakpoint(large) {
    height: 80vh;
    overflow-y: scroll;
  }
  .is-active {
    color: $black;
  }
}
//BIO TEAM PAGES
.bio-header {
  background: linear-gradient(180deg, rgba($light-gray, 1), rgba($light-gray, 0));
  @include breakpoint(medium) {
    padding-top: 6em;
    .content {
      margin-top: 1em;
    }
  }
  @include breakpoint(large) {
    padding-top: 8em;
    .content {
      margin-top: 2em;
    }
  }
  figure {
    overflow: hidden;
    .bio-image {
      box-shadow: 0px 8px 12px 0px rgba($gray, 0.15);
      border-radius: $global-radius;
      margin-bottom: 20%;
      float: left;
    }
    .bio-image-hover {
      float: right;
      margin-right: -100%;
    }
    &.hover {
      &:hover {
        .bio-image {
          margin-left: -100%;
        }
        .bio-image-hover {
          margin-right: 0;
        }
      }
    }
  }
  .callout {
    border: none;
    background-color: transparent;
    padding: 0 1em 1em 1em;
    h4 {
      font-size: f-header-size(small, h4);
    }
    ul {
      list-style: none;
      margin: 0 0 1em 0;
      li {
        color: $gray;
        font-size: .85em;
        line-height: 1.15em;
        margin-bottom: .5em;
        &:after {
          display: none;
        }
      }
    }
  }
  .social-icons {
    margin-top: 1em;
  }
}
h2.bio-image-title {
  font-size: f-header-size(small, h5);
  font-weight: normal;
  padding: .5em 0;
  display: block;
}
blockquote.breakout {
  border-top: 2px solid $primary-color;
  margin: 2em 0em;
  padding-top: 2em;
  border-left: none;
  display: relative;
  font-weight: bold;
  p {
    margin: 0;
    color: $body-font-color;
  }
  @include breakpoint(large) {
    p {
      font-size: 150%;
    }
  }
  footer {
    margin: 10px 0;
    color: $gray;
    &:before {
      content: "-";
      margin-right: .25em;
    }
  }
}
//
.subpage {
  h2 {
    display: initial;
  }
  .hero {
    background-size: 100%;
    position: relative;
    min-height: 100%;
    background-position: right center;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-color: $primary-color;
    .breadcrumbs {
      color: $white;
      a {
        color: $white;
      }
      .breadcrumb_last {
        color: color.adjust($primary-color, $lightness: 30%);
      }
    }
    .hero-banner {
      display: flex;
    }
    .hero-image {
      text-align: right;
      background-position: right center;
      background-size: cover !important;
      min-height: 280px;
    }
    .hero-caption {
      align-items: center;
      padding: 2em 0;
      position: relative;
      h1,
      h2 {
        color: $white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        font-weight: 700;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          font-weight: 700;
          display: inline-block;
          &:after {
            content: '';
            display: block;
            left: 0;
            height: 3px;
            width: 50%;
            background: $primary-color;
            transition: width 0.5s ease, background-color 0.25s ease;
          }
          &:hover:after {
            width: 100%;
            background: $primary-color;
          }
        }
      }
    }
    @include breakpoint(medium) {
      .hero-image {
        min-height: 500px;
      }
      .hero-caption {
        padding: 6em 0 2em;
      }
    }
    @include breakpoint(large) {
      .hero-image {
        min-height: 500px;
        z-index: -1em;
        position: relative;
        //   background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 10%);
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 20%);
        }
      }
      .hero-caption {
        z-index: 2;
        h1 {
          @include header-size(h1);
        }
        h2 {
          @include header-size(h2);
        }
      }
    }
    @include breakpoint(xlarge) {
      .hero-image {
        min-height: 550px;
      }
      .hero-caption {
        // margin-left: 10%;
      }
    }
    @include breakpoint(xxlarge) {
      .hero-caption {
      }
    }
  }
  &.faqs {
    .callout {
      margin: 0 0 2em 0;
      box-sizing: content-box;
    }
  }
}
.default {
  h2 {
    display: initial;
  }
  .hero {
    background-size: 100%;
    position: relative;
    min-height: 100%;
    background-position: right center;
    background-repeat: no-repeat;
    background-color: $primary-color;
    .hero-banner {
      display: flex;
    }
    .hero-caption {
      text-align: center;
      align-items: center;
      position: relative;
      padding: 1em;
      h2 {
        font-weight: 300;
      }
      h1 {
        font-weight: 700;
        color: $white;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          font-weight: 700;
          display: inline-block;
          &:after {
            content: '';
            display: block;
            left: 0;
            height: 3px;
            width: 50%;
            background: $primary-color;
            transition: width 0.5s ease, background-color 0.25s ease;
          }
          &:hover:after {
            width: 100%;
            background: $primary-color;
          }
        }
      }
    }
    @include breakpoint(large) {
      .hero-caption {
        z-index: 2;
        padding: 2em 2em 2em 0;
        text-align: left;
        h1 {
          @include header-size(h1);
        }
        h2 {
          @include header-size(h2);
        }
      }
    }
  }
}
// WORDPRESS DEFAULTS
//////////////////////////////////////////////////////////////////////////
.wp-caption {
  position: relative;
  .wp-caption-text {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $white;
    font-size: .9em;
    padding: 1em;
    display: block;
    box-shadow: -8px 8px 12px 0px rgba($gray, 0.15);
    @include breakpoint(large) {
      padding: 1.5em;
    }
  }
  &.alignright {
    .wp-caption-text {
      right: 0;
      left: unset;
      box-shadow: 8px 8px 12px 0px rgba($gray, 0.15);
    }
  }
  &.alignnone {
    .wp-caption-text {
      position: relative;
      right: 0;
      left: unset;
      box-shadow: 8px 8px 12px 0px rgba($gray, 0.15);
    }
  }
}
// WordPress alignment classes
.aligncenter {
  display: flex;
  flex: 1;
  margin: auto;
}
.alignright {
  float: right;
  margin-left: auto;
}
.alignleft {
  float: left;
  margin-right: auto;
}
// ANIMATIONS
//////////////////////////////////////////////////////////////////////////
.animate {
  opacity: 0;
}
.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(-2%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.fadein {
  opacity: 0;
}
.fadeout {
  opacity: 1;
}
.fadein-animation {
  animation-name: fadein-animation;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes fadein-animation {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.fadeout-animation {
  animation-name: fadeout-animation;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes fadeout-animation {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  25% {
    transform: translateY(0%);
  }
  75% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}
.stak.photo-gallery.custom.badges {
  @include breakpoint(medium down) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .column {
    @include breakpoint(small down) {
      flex: 0 0 33.3333333333% !important;
      max-width: 33.3333333333% !important;
    }
  }
}
.author-bio {
  background: #F9F9F9;
  padding: 30px;
  margin-bottom: 30px;
  .max-thousand {
    max-width: 1000px;
    gap: 35px;
    .card {
      padding: 1em;
      transition: .25s all ease-in-out;
      img {
        width: 100%;
      }
      .card-section {
        text-align: center;
        h5 {
          color: $primary-color;
          font-weight: bold;
        }
      }
    }
  }
}

// REMOVED FROM APPEARANCE ADDITIONAL CSS
//header#global_header {
//  padding-bottom: 10px;
//}
.main_data.contact-top-sec .image_section {
  padding-right: 3%;
}
.ancillary .ico-da-tv:before{
  display:none;
}
.ancillary ul.ancillary-menu li:nth-child(2)  a {
  color:	#023020 !important;
}

.ancillary ul.ancillary-menu li:nth-child(3)  a {
  color:	#027A0D !important;
}
.ancillary ul.ancillary-menu {
  justify-content: space-between;
  display: flex;
}
.ancillary ul.ancillary-menu li a {
  &:not(.phone, .button) {
    display: none;
  }
}
@include breakpoint(medium) {
  .ancillary ul.ancillary-menu {
    justify-content: flex-end;
  }
  .ancillary ul.ancillary-menu li a {
    &:not(.phone, .button) {
      display: inline-block;
    }
  }
}
.off-canvas-ancillary {
  background-color: color.adjust($light-gray, $lightness: -5%);
    a.phone {
      display: none!important;
    }
  a.button {
    background-color: $primary-color;
    color: $white!important;
    border-radius: 0;
  }

}

footer.global-footer .telephone span{
  width:3.5em;
}

.main_data.contact-top-sec .form_section form .nf-row input {
  min-height: 35px;
  height: 26px;
}
.main_data.contact-top-sec .form_section form .nf-form-content textarea.ninja-forms-field {
  min-height: 85px;
  height: 70px;
}
.main_data.contact-top-sec .image_section a.get-direction-btn {
  background: #71b54e;
  color: white;
  padding: 8px 17px;
  position: relative;
  top: 15px;
  border-radius: 3px;
  text-decoration: none;
}
.main_data.contact-top-sec .image_section a.get-direction-btn:hover {
  background: #5bbb29;
}
footer#footer .medium-order-3 address {
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .main_data.contact-top-sec .image_section {
    width: 100% !important;
    padding-right: 0;
  }
  .main_data.contact-top-sec .form_section {
    width: 100% !important;
  }
  .main_data.contact-top-sec .form_section form .nf-form-content #nf-field-10-wrap .ninja-forms-field {
    font-size: 14px;
  }
  /* va-malpractice-lawyers Page */
  .video-wrapper {
    margin-left:0px !important;
    margin-top:-840px !important;
    padding-bottom:710px !important;

  }
  .video-wrapper iframe {
    width: 300px !important;
    height: 165px !important;
  }
}
@media screen and (max-width: 359px) {
  .main_data.contact-top-sec .form_section form .nf-form-content #nf-field-10-wrap .ninja-forms-field {
    font-size: 12px;
  }
}
@media only screen and (max-width: 600px){
  .home-hero {
    margin-top: 23px;
    height: 76vh;
  }
}
/* va-malpractice-lawyers Page */
@media only screen and (min-width: 800px){
  .video-wrapper {
    float:right !important;
    margin-top:-420px !important;
    padding-bottom:140px !important;

  }
  .video-wrapper iframe {
    width: 560px !important;
    height: 315px !important;
  }
}
.photo-gallery.custom.badges .column {
  max-width: 13% !important;
}
.home-hero {margin-top: 100px;}

/** hideo google review home page**/
.home-hero .ti-widget {
  display: none !important;
}
/** end hideo google review home page**/